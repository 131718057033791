import * as React from "react";
import {Button} from "@components/Button";

import * as styles from "./styles.module.scss";
import logoNGL from "@assets/images/ngl.png";

export const NavBar = () => {
	return (
		<nav className={styles.navbar}>
			<img className={styles.logo} src={logoNGL} alt="Logo NGL" />
			<ul className={styles.links}>
				<li>
					<a href="https://ngl.link/#what-s_ngl">What's NGL?</a>
				</li>
				<li>
					<a href="https://ngl.link/safety">Safety Center</a>
				</li>
				<li>
					<a href="https://ngl.link/community-guidelines">Guidelines</a>
				</li>
				<li>
					<a href="https://ngl.link/#FAQ">FAQ</a>
				</li>
			</ul>
			<Button>
				<a href="http://ngl.app.link/">Get NGL</a>
			</Button>
		</nav>
	);
};
