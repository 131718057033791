import * as React from "react";
import {NavBar} from "@components/NavBar";

import * as styles from "./styles.module.scss";

export const Layout = ({children}) => {
	return (
		<div className={styles.layout}>
			<NavBar />
			{children}
		</div>
	);
};
