import * as React from "react";

import * as styles from "./styles.module.scss";

export const Button = ({children, className, ...rest}) => {
	return (
		<button className={`${styles.button} ${className}`} {...rest}>
			{children}
		</button>
	);
};
